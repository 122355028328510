@import 'declarations';

$py-colors: (
  'white': #ffffff,
  'black': #212529,
  // Grey
  'grey-900': #141415,
  'grey-800': #28282a,
  'grey-700': #3c3d3e,
  'grey-600': #505153,
  'grey-500': #646568,
  'grey-400': #828385,
  'grey-300': #cbcbcd,
  'grey-200': #e5e5e6,
  'grey-100': #f2f2f2,
  // Info
  'info-900': #0c1d35,
  'info-800': #183864,
  'info-700': #255293,
  'info-600': #316dc2,
  'info-500': #3d87f1,
  'info-400': #649ff4,
  'info-300': #8bb7f7,
  'info-200': #c5dbfb,
  'info-100': #ebf2fd,
  // Success
  'success-900': #1d270b,
  'success-800': #3a4d17,
  'success-700': #587422,
  'success-600': #759a2e,
  'success-500': #92c139,
  'success-400': #a8cd61,
  'success-300': #beda88,
  'success-200': #deecc4,
  'success-100': #f3f9e9,
  // Warning
  'warning-900': #4d3c00,
  'warning-800': #6d5a15,
  'warning-700': #9d831f,
  'warning-600': #ceab2a,
  'warning-500': #ffd434,
  'warning-400': #ffdd5d,
  'warning-300': #ffe585,
  'warning-200': #ffeeae,
  'warning-100': #ddd,
  // Danger
  'danger-900': #3a0d0d,
  'danger-800': #681a1a,
  'danger-700': #900909,
  'danger-600': #c53333,
  'danger-500': #f43f3f,
  'danger-400': #f66565,
  'danger-300': #f88c8c,
  'danger-200': #fdd9d9,
  'danger-100': #feecec,
  // Button colors
  'button-primary': #1b7e5a,
  'button-secondary': #092a1e,
  'button-danger': #f43f3f,
  'button-locked': #e5e5e6,
  // Line
  'line-hard': #828385,
  'line-steady': #cbcbcd,
  'line-soft': #e5e5e6,
  // Global
  'global-selected': #fde6ce,
  'global-hover': #ffd09e,
  'global-active': #f78f1e,
  'global-active-hover': #945612,
  'global-tooltip': #646568,

  // Deprecated colors from legacy designs - DO NOT USE IN NEWLY DEVELOPED CODE
  // TODO ESVCX-5948 - remove legacy colors
  // Deprecated colors START
  'primary-dark': #d67308,
  'primary': #f78f1e,
  'primary-light': #fde6ce,
  'secondary-darker': #092a1e,
  'secondary-dark': #12543c,
  'secondary': #1b7e5a,
  'secondary-light': #29bd87,
  'grey-dark': #646568,
  'grey': #cbcbcd,
  'grey-light': #e5e5e6,
  'grey-lighter': #f2f2f2,
  'success': #1b7e5a,
  'success-darker': #092a1e,
  'success-secondary': #92c139,
  'success-secondary-light': #f3f9e9,
  'warning': #ffd434,
  'warning-darker': #4d3c00,
  'danger': #f43f3f,
  'danger-darker': #900909,
  'info': #3d87f1,
  'info-darker': #332800,
  'info-dark': #255293,
  // Deprecated colors END
) !default;

:root {
  @include generate-py-variables($py-colors);
}
